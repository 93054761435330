<!-- Start Main Banner Area -->
<div class="main-banner">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container-fluid">
        <div class="main-banner-content">
          <h1>{{ "mainBanner.title" | translate }}</h1>
          <p class="font-weight-bold">
            {{ "mainBanner.description" | translate }}
          </p>

          <div class="btn-box">
            <a href="homefr#servicesArea" class="btn btn-primary rounded">{{
              "mainBanner.button" | translate
            }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Main Banner Area -->

<div class="featured-services-area">
  <div class="container">
    <div class="featured-services-inner">
      <div class="row m-0">
        <div class="col-lg-4 col-md-6 col-sm-6 p-0">
          <div class="single-featured-services-box">
            <div class="icon">
              <i class="flaticon-machine-learning"></i>
            </div>
            <h3><a>{{"featuredServices.dataScience.title" | translate}}</a></h3>
            <p>
              {{"featuredServices.dataScience.description" | translate}}
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6 p-0">
          <div class="single-featured-services-box">
            <div class="icon">
              <i class="flaticon-artificial-intelligence"></i>
            </div>
            <h3><a>{{"featuredServices.machineLearning.title" | translate}}</a></h3>
            <p>
              {{"featuredServices.machineLearning.description" | translate}}
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6 p-0">
          <div class="single-featured-services-box">
            <div class="icon">
              <i class="flaticon-robot"></i>
            </div>
            <h3><a>{{"featuredServices.dataAnalytics.title" | translate}}</a></h3>
            <p>
              {{"featuredServices.dataAnalytics.description" | translate}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="about-area ptb-110">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="about-content">
          <h2>{{ 'about.missionTitle' | translate }}</h2>
          <p>
            {{ 'about.missionDescription1' | translate }}
          </p>
          <p>
            {{ 'about.missionDescription2' | translate }}
          </p>
          <a routerLink="/about" class="btn btn-primary rounded"
            >{{ 'about.learnMoreButton' | translate }}</a
          >
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="about-image">
          <img
            src="assets/img/about/1.jpg"
            alt="image"
            width="550px"
            height="360px"
            style="box-shadow: 0 0 2em grey; border-radius: 4px"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="shape-img1">
    <img src="assets/img/shape/1.png" alt="image" />
  </div>
  <div class="shape-img2">
    <img src="assets/img/shape/2.svg" alt="image" />
  </div>
  <div class="shape-img3">
    <img src="assets/img/shape/3.png" alt="image" />
  </div>
  <div class="shape-img4">
    <img src="assets/img/shape/4.svg" alt="image" />
  </div>
  <div class="shape-img5">
    <img src="assets/img/shape/5.svg" alt="image" />
  </div>
  <div class="shape-img6">
    <img src="assets/img/shape/6.png" alt="image" />
  </div>
  <div class="dot-shape1">
    <img src="assets/img/shape/dot1.png" alt="image" />
  </div>
  <div class="dot-shape2">
    <img src="assets/img/shape/dot3.png" alt="image" />
  </div>
</section>

<app-features></app-features>
<section class="services-area ptb-110" id="servicesArea">
  <div class="container">
    <div class="section-title">
      <h2>{{"offer" |translate}}</h2>
    </div>

    <div class="container-fluid py-5" id="poledataTitle">
      <div class="container py-5">
        <div class="row">
          <div class="col-lg-7 pt-lg-5 pb-3">
            <h1 class="mb-4">{{ 'pole' | translate }} <span class="text-warning">{{ 'services.dataPole.title' | translate }} </span></h1>
            <h2 class="text-warning">
              {{ 'services.dataPole.subtitle' | translate }} 
            </h2>
            <p class="mb-4">
              {{ 'services.dataPole.description' | translate }} 
            </p>
            <h6 class="text-warning">
              {{ 'services.dataPole.technologies' | translate }} 
            </h6>
          </div>
          <div class="col-lg-5" style="min-height: 400px">
            <div class="position-relative h-100 rounded overflow-hidden">
              <img
                class="position-absolute w-100 h-100"
                src="assets/images/dataCentre.png"
                style="object-fit: cover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid py-5" id="poledevopsTitle">
      <div class="container py-5">
        <div class="row">
          <div class="col-lg-5" style="min-height: 400px">
            <div class="position-relative h-100 rounded overflow-hidden">
              <img
                class="position-absolute w-100 h-100"
                src="assets/images/DevOps.png"
                style="object-fit: cover"
              />
            </div>
          </div>
          <div class="col-lg-7 pt-lg-5 pb-3">
            <h1 class="mb-4">{{ 'pole' | translate }}  <span class="text-primary">{{ 'services.devOpsPole.title' | translate }} </span></h1>
            <h2 class="text-primary">{{ 'services.devOpsPole.subtitle' | translate }} </h2>
            <p class="mb-4">
              {{ 'services.devOpsPole.description' | translate }}
            </p>
            <h6 class="text-primary">
              {{ 'services.devOpsPole.technologies' | translate }}
            </h6>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid py-5" id="polecyberTitle">
      <div class="container py-5">
        <div class="row">
          <div class="col-lg-7 pt-lg-5 pb-3">
            <h1 class="mb-4">
              {{ 'pole' | translate }}  <span class="text-gray">{{ 'services.cyberSecurityPole.title' | translate }} </span>
            </h1>
            <h2 class="text-gray">{{ 'services.cyberSecurityPole.subtitle' | translate }}</h2>
            <p class="mb-4">
              {{ 'services.cyberSecurityPole.description' | translate }}
            </p>
            <h6 class="text-gray">
              {{ 'services.cyberSecurityPole.technologies' | translate }}
            </h6>
          </div>
          <div class="col-lg-5" style="min-height: 400px">
            <div class="position-relative h-100 rounded overflow-hidden">
              <img
                class="position-absolute w-100 h-100"
                src="assets/images/cyberSec.png"
                style="object-fit: cover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid py-5" id="poleformationTitle">
      <div class="container py-5">
        <div class="row">
          <div class="col-lg-7 pt-lg-5 pb-3">
            <h1 class="mb-4">{{ 'pole' | translate }}  <span class="text-green">{{ 'services.trainingPole.title' | translate }}</span></h1>
            <h2 class="text-green">{{ 'services.trainingPole.subtitle' | translate }}</h2>
            <p class="mb-4">
              {{ 'services.trainingPole.description' | translate }}
            </p>
            <h6 class="text-green">
              {{ 'services.trainingPole.technologies' | translate }}
            </h6>
          </div>
          <div class="col-lg-5" style="min-height: 400px">
            <div class="position-relative h-100 rounded overflow-hidden">
              <img
                class="position-absolute w-100 h-100"
                src="assets/images/formation.png"
                style="object-fit: cover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid py-5" id="polesupportTitle">
      <div class="container py-5">
        <div class="row">
          <div class="col-lg-5" style="min-height: 400px">
            <div class="position-relative h-100 rounded overflow-hidden">
              <img
                class="position-absolute w-100 h-100"
                src="assets/images/support.png"
                style="object-fit: cover"
              />
            </div>
          </div>
          <div class="col-lg-7 pt-lg-5 pb-3">
            <h1 class="mb-4">{{ 'pole' | translate }}  <span class="text-purple">{{'services.supportPole.title' | translate }}</span></h1>
            <h2 class="text-purple">{{ 'services.supportPole.subtitle' | translate }}</h2>
            <p class="mb-4">
              {{ 'services.supportPole.description' | translate }}
            </p>
            <h6 class="text-purple">{{ 'services.supportPole.technologies' | translate }}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <app-key-numbers></app-key-numbers>
<app-ambition></app-ambition> -->
<app-partners></app-partners>

<!-- Start Services Area -->
<!-- <section class="services-area ptb-110" id="servicesArea">
  <div class="container">
    <div class="section-title">
      <h2>Notre Expertise</h2>
      <p>
        L’équipe est structurée en 3 pôles d’expertise : conseil en Data
        Science,Machine Learning et Business Intelligence.
      </p>
    </div>

    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-6">
        <a href="#">
          <div class="single-services-box">
            <div class="icon">
              <i class="flaticon-search"></i>
            </div>
            <h3>Data Science</h3>
            <p>
              Nous vous aidons à exploiter les données de votre entreprise pour
              comprendre le passé, anticiper le futur et vous recommander des
              solutions pour l’avenir. <br />
              Notre équipe déploient des modèles statistiques pour répondre à
              vos questions, telles que : anticiper les volumes de ventes..
            </p>
          </div>
        </a>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-6">
        <a href="#">
          <div class="single-services-box">
            <div class="icon">
              <i class="flaticon-machine-learning"></i>
            </div>
            <h3>Machine Learning</h3>
            <p>
              Nous vous accompagnons dans le développement des modeles
              statistiques machine learning afin de valoriser vos données et
              d'en extraire des insights.
            </p>
          </div>
        </a>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-6">
        <a href="#">
          <div class="single-services-box">
            <div class="icon">
              <i class="flaticon-gear"></i>
            </div>
            <h3>Business Intelligence</h3>
            <p>
              Nos collaborateurs vous aident à trouver la meilleure façon de
              collecter, stocker et structurer vos données en fonction de votre
              stratégie. Nous proposons un accompagnement de conseil et
              d’expertise technique adapté à votre transformation et votre degré
              d’avancement.
            </p>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <a href="#">
          <div class="single-services-box">
            <div class="icon">
              <i class="flaticon-automatic"></i>
            </div>
            <h3>Data Analytics</h3>
            <p>
              Notre mission est d’accompagner les entreprises dans le traitement
              de leur données à l’aide des solutions phares du marché : Power
              Bi, Tableau Software, Qlik Sense et d’autres.
            </p>
          </div>
        </a>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-6">
        <a href="#">
          <div class="single-services-box">
            <div class="icon">
              <i class="flaticon-robot"></i>
            </div>
            <h3>Data Visualisation</h3>
            <p>
              Nos consultants vous aident à rendre la donnée accessible à tous
              les utilisateurs. Nous vous accompagnons dans l'élaboration des
              tableaux de bord, des rapports et des visualisations qui
              permettent de prendre des décisions basées sur les données.
            </p>
          </div>
        </a>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-6">
        <a href="#">
          <div class="single-services-box">
            <div class="icon">
              <i class="flaticon-artificial-intelligence"></i>
            </div>
            <h3>Intelligence Artificielle</h3>
            <p>
              Nos solutions en Intelligence Artificielle vous aident à
              automatiser vos processus, améliorer l'expérience client et
              développer de nouveaux produits et services innovants.
            </p>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <a href="#">
          <div class="single-services-box">
            <div class="icon">
              <i class="flaticon-locked"></i>
            </div>
            <h3>Sécurité </h3>
            <p>
              Nos consultants certifiés et hautement qualifiés possèdent une
              expertise approfondie en sécurité cloud, en détection de
              vulnérabilités, et en déploiement de solutions sur mesure adaptées
              à votre environnement.
            </p>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <a href="#">
          <div class="single-services-box">
            <div class="icon">
              <i class="flaticon-income"></i>
            </div>
            <h3>Infrastructure</h3>
            <p>
              Nos consultants apportent une expertise riche et variée, acquise
              auprès de nombreux clients. Ils maîtrisent parfaitement les
              environnements On-Premise ainsi que les principales plateformes
              Cloud, telles que Azure, Google Cloud Platform (GCP)
            </p>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <a href="#">
          <div class="single-services-box">
            <div class="icon">
              <i class="flaticon-molecular"></i>
            </div>
            <h3>Volet Data</h3>
            <p>
              Nos consultants, experts en valorisation des données, collectent,
              traitent et analysent vos données pour en faire un levier de
              performance, offrant des insights pertinents et des décisions
              éclairées pour votre entreprise.
            </p>
          </div>
        </a>
      </div>
    </div>
  </div>

  <div class="shape-img1">
    <img src="assets/img/shape/1.png" alt="image" />
  </div>
  <div class="shape-img2">
    <img src="assets/img/shape/2.svg" alt="image" />
  </div>
  <div class="shape-img3">
    <img src="assets/img/shape/3.png" alt="image" />
  </div>
  <div class="shape-img4">
    <img src="assets/img/shape/4.svg" alt="image" />
  </div>
  <div class="shape-img5">
    <img src="assets/img/shape/5.svg" alt="image" />
  </div>
  <div class="shape-img6">
    <img src="assets/img/shape/6.png" alt="image" />
  </div>
  <div class="dot-shape1">
    <img src="assets/img/shape/dot1.png" alt="image" />
  </div>
  <div class="dot-shape2">
    <img src="assets/img/shape/dot3.png" alt="image" />
  </div>
</section> -->
<!-- End Services Area -->
<!-- Start Training FR Area -->
<section class="blog-area ptb-110">
  <div class="container">
    <div class="section-title">
      <h2>{{"training_section.section_title" | translate}}</h2>
      <p>
        {{"training_section.description" | translate}}
      </p>
    </div>

    <div class="row">
      <div class="col-lg-5 col-md-5 ml-5 mr-3">
        <div class="single-blog-post">
          <div class="entry-thumbnail">
            <a routerLink="/training1"
              ><img
                src="assets/img/services-details/azure.jpg"
                alt="image"
                width="860px"
                height="230px"
            /></a>
          </div>
          <div class="entry-post-content">
            <h3>
              <a routerLink="/training1"
                >{{"training_section.training1_title" | translate}}</a
              >
            </h3>
            <a routerLink="/training1" class="learn-more-btn text-dark"
              >{{"training_section.learn_more" | translate}} <i class="flaticon-add"></i
            ></a>
          </div>
        </div>
      </div>

      <div class="col-lg-5 col-md-5 ml-3">
        <div class="single-blog-post">
          <div class="entry-thumbnail">
            <a routerLink="training2"
              ><img
                src="assets/img/services-details/powerbi1.jpg"
                alt="image"
                width="860px"
                height="230px"
            /></a>
          </div>
          <div class="entry-post-content">
            <h3>
              <a routerLink="/training2"
                >{{"training_section.training2_title" | translate}}</a
              >
            </h3>
            <a routerLink="/training2" class="learn-more-btn text-dark"
              >{{"training_section.learn_more" | translate}}  <i class="flaticon-add"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="shape-img2">
    <img src="assets/img/shape/2.svg" alt="image" />
  </div>
  <div class="shape-img3">
    <img src="assets/img/shape/3.png" alt="image" />
  </div>
  <div class="shape-img4">
    <img src="assets/img/shape/4.svg" alt="image" />
  </div>
  <div class="shape-img5">
    <img src="assets/img/shape/5.svg" alt="image" />
  </div>
  <div class="shape-img7">
    <img src="assets/img/shape/3.png" alt="image" />
  </div>
  <div class="dot-shape1">
    <img src="assets/img/shape/dot1.png" alt="image" />
  </div>
  <div class="dot-shape2">
    <img src="assets/img/shape/dot3.png" alt="image" />
  </div>
  <div class="dot-shape4">
    <img src="assets/img/shape/dot4.png" alt="image" />
  </div>
  <div class="dot-shape5">
    <img src="assets/img/shape/dot5.png" alt="image" />
  </div>
  <div class="dot-shape6">
    <img src="assets/img/shape/dot6.png" alt="image" />
  </div>
</section>
<!-- End Training FR Area -->
