<div class="page-title-area item-bg2">
  <div class="container">
    <div class="page-title-content">
      <h2>{{ "references" | translate }}</h2>
    </div>
  </div>
  <div class="lines">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </div>
</div>
<section class="contact-area ptb-110">
  <div class="section-title">
    <h2>{{ "our_trusted_clients" | translate }}</h2>
  </div>
</section>
<div class="container-fluid py-5">
  <div class="container py-5">
    <div class="row">
      <div class="col-lg-7 pt-lg-5 pb-3">
        <h6 class="text-secondary font-weight-semi-bold text-uppercase mb-3">
          {{ "references" | translate }}
        </h6>
        <h1 class="mb-4">
          {{ "pole" | translate }}
          <span class="text-warning"
            >{{ "poles.data.title" | translate }}
          </span>
        </h1>
        <div class="row">
          <div
            class="icon-box bg-warning text-white rounded-circle d-flex align-items-center justify-content-center"
            style="width: 60px; height: 60px"
          >
            <i class="fa fa-2x fa-user"></i>
          </div>
          <div class="col">
            <h2 class="text-warning mb-2" data-toggle="counter-up">CLIENT</h2>
            <h6 class="font-weight-semi-bold mb-sm-4">
              {{ "poles.data.veolia.client" | translate }}
            </h6>
          </div>
          <div
            class="icon-box bg-warning text-white rounded-circle d-flex align-items-center justify-content-center"
            style="width: 60px; height: 60px"
          >
            <i class="fa fa-2x fa-chart-pie"></i>
          </div>
          <div class="col">
            <h2 class="text-warning mb-2" data-toggle="counter-up">
              {{ "poles.data.veolia.sector" | translate }}
            </h2>
            <h6 class="font-weight-semi-bold mb-sm-4">
              {{ "poles.data.veolia.industry" | translate }}
            </h6>
          </div>
          <div
            class="icon-box text-white rounded-circle d-flex align-items-center justify-content-center"
            style="width: 60px; height: 60px"
          >
            <img
              src="https://static-00.iconduck.com/assets.00/google-cloud-platform-logo-icon-2048x1824-pg4wzspq.png"
              alt=""
            />
          </div>
          <div class="col">
            <h2 class="text-warning mb-2" data-toggle="counter-up">
              {{ "poles.data.veolia.cloud" | translate }}
            </h2>
            <h6 class="font-weight-semi-bold mb-sm-4">
              {{ "poles.data.veolia.gcp" | translate }}
            </h6>
          </div>
        </div>
        <h2 class="text-warning">
          {{ "poles.data.veolia.description" | translate }}
        </h2>
        <p class="mb-4">
          {{ "poles.data.veolia.text" | translate }}
        </p>
        <h6 class="text-warning">
          {{ "poles.data.veolia.technologies" | translate }}
        </h6>
      </div>
      <div class="col-lg-5" style="min-height: 400px">
        <div class="position-relative h-100 rounded overflow-hidden">
          <img
            class="position-absolute w-100 h-100"
            src="https://images.pexels.com/photos/5717724/pexels-photo-5717724.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            style="object-fit: cover"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid py-5">
  <div class="container py-5">
    <div class="row">
      <div class="col-lg-5" style="min-height: 400px">
        <div class="position-relative h-100 rounded overflow-hidden">
          <img
            class="position-absolute w-100 h-100"
            src="https://images.pexels.com/photos/3861951/pexels-photo-3861951.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            style="object-fit: cover"
          />
        </div>
      </div>
      <div class="col-lg-7 pt-lg-5 pb-3">
        <h6 class="text-primary font-weight-semi-bold text-uppercase mb-3">
          {{ "references" | translate }}
        </h6>
        <h1 class="mb-4">
          {{ "pole" | translate }}
          <span class="text-primary">{{
            "poles.devops.title" | translate
          }}</span>
        </h1>
        <div class="row">
          <div
            class="icon-box bg-primary text-white rounded-circle d-flex align-items-center justify-content-center"
            style="width: 60px; height: 60px"
          >
            <i class="fa fa-2x fa-user"></i>
          </div>
          <div class="col">
            <h2 class="text-primary mb-2" data-toggle="counter-up">CLIENT</h2>
            <h6 class="font-weight-semi-bold mb-sm-4">
              {{ "poles.devops.air_france.client" | translate }}
            </h6>
          </div>
          <div
            class="icon-box bg-primary text-white rounded-circle d-flex align-items-center justify-content-center"
            style="width: 60px; height: 60px"
          >
            <i class="fa fa-2x fa-chart-pie"></i>
          </div>
          <div class="col">
            <h2 class="text-primary mb-2" data-toggle="counter-up">
              {{ "poles.data.veolia.sector" | translate }}
            </h2>
            <h6 class="font-weight-semi-bold mb-sm-4">
              {{ "poles.devops.air_france.sector" | translate }}
            </h6>
          </div>
          <div
            class="icon-box text-white rounded-circle d-flex align-items-center justify-content-center"
            style="width: 60px; height: 60px"
          >
            <img src="https://swimburger.net/media/ppnn3pcl/azure.png" alt="" />
          </div>
          <div class="col">
            <h2 class="text-primary mb-2" data-toggle="counter-up">CLOUD</h2>
            <h6 class="font-weight-semi-bold mb-sm-4">AZURE</h6>
          </div>
        </div>
        <h2 class="text-primary">
          {{ "poles.devops.air_france.description" | translate }}
        </h2>
        <p class="mb-4">
          {{ "poles.devops.air_france.text" | translate }}
        </p>
        <h6 class="text-primary">
          {{ "poles.devops.air_france.technologies" | translate }}
        </h6>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid py-5">
  <div class="container py-5">
    <div class="row">
      <div class="col-lg-7 pt-lg-5 pb-3">
        <h6 class="text-secondary font-weight-semi-bold text-uppercase mb-3">
          {{ "references" | translate }}
        </h6>
        <h1 class="mb-4">
          {{ "pole" | translate }}
          <span class="text-secondary"
            >{{ "poles.cyber_security.title" | translate }}
          </span>
        </h1>
        <div class="row">
          <div
            class="icon-box bg-secondary text-white rounded-circle d-flex align-items-center justify-content-center"
            style="width: 60px; height: 60px"
          >
            <i class="fa fa-2x fa-user"></i>
          </div>
          <div class="col">
            <h2 class="text-secondary mb-2" data-toggle="counter-up">CLIENT</h2>
            <h6 class="font-weight-semi-bold mb-sm-4">L’Oreal</h6>
          </div>
          <div
            class="icon-box bg-secondary text-white rounded-circle d-flex align-items-center justify-content-center"
            style="width: 60px; height: 60px"
          >
            <i class="fa fa-2x fa-chart-pie"></i>
          </div>
          <div class="col">
            <h2 class="text-secondary mb-2" data-toggle="counter-up">
              {{ "poles.data.veolia.sector" | translate }}
            </h2>
            <h6 class="font-weight-semi-bold mb-sm-4">CYBER</h6>
          </div>
          <div
            class="icon-box text-white rounded-circle d-flex align-items-center justify-content-center"
            style="width: 60px; height: 60px"
          >
            <img src="https://swimburger.net/media/ppnn3pcl/azure.png" alt="" />
          </div>
          <div class="col">
            <h2 class="text-secondary mb-2" data-toggle="counter-up">CLOUD</h2>
            <h6 class="font-weight-semi-bold mb-sm-4">AZURE</h6>
          </div>
        </div>
        <h2 class="text-secondary">{{"poles.cyber_security.loreal.description" | translate}}</h2>
        <p class="mb-4 text-black">
          {{"poles.cyber_security.loreal.text" | translate}}
        </p>
        <h6 class="text-secondary">
          {{"poles.cyber_security.loreal.technologies" | translate}}
        </h6>
      </div>
      <div class="col-lg-5" style="min-height: 400px">
        <div class="position-relative h-100 rounded overflow-hidden">
          <img
            class="position-absolute w-100 h-100"
            src="https://images.pexels.com/photos/60504/security-protection-anti-virus-software-60504.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            style="object-fit: cover"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid py-5">
  <div class="container py-5">
    <div class="row">
      <div class="col-lg-5" style="min-height: 400px">
        <div class="position-relative h-100 rounded overflow-hidden">
          <img
            class="position-absolute w-100 h-100"
            src="https://images.pexels.com/photos/4974914/pexels-photo-4974914.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            style="object-fit: cover"
          />
        </div>
      </div>
      <div class="col-lg-7 pt-lg-5 pb-3">
        <h6 class="text-secondary font-weight-semi-bold text-uppercase mb-3">
          {{ "references" | translate }}
        </h6>
        <h1 class="mb-4">
          {{ "pole" | translate }} <span class="text-success">{{"poles.training.title" | translate}}</span>
        </h1>
        <div class="row">
          <div
            class="icon-box bg-success text-white rounded-circle d-flex align-items-center justify-content-center"
            style="width: 60px; height: 60px"
          >
            <i class="fa fa-2x fa-user"></i>
          </div>
          <div class="col">
            <h2 class="text-success mb-2" data-toggle="counter-up">CLIENT</h2>
            <h6 class="font-weight-semi-bold mb-sm-4">CELIO</h6>
          </div>
          <div
            class="icon-box bg-success text-white rounded-circle d-flex align-items-center justify-content-center"
            style="width: 60px; height: 60px"
          >
            <i class="fa fa-2x fa-chart-pie"></i>
          </div>
          <div class="col">
            <h2 class="text-warsuccessning mb-2" data-toggle="counter-up">
              {{ "poles.data.veolia.sector" | translate }}
            </h2>
            <h6 class="font-weight-semi-bold mb-sm-4">RETAIL</h6>
          </div>
          <div
            class="icon-box bg-success text-white rounded-circle d-flex align-items-center justify-content-center"
            style="width: 60px; height: 60px"
          >
            <i class="fa fa-star"></i>
          </div>
          <div class="col">
            <h2 class="text-success mb-2" data-toggle="counter-up">CLOUD</h2>
            <h6 class="font-weight-semi-bold mb-sm-4">AZURE</h6>
          </div>
        </div>
        <h2 class="text-success">
          {{"poles.training.celio.description" | translate}}
        </h2>
        <p class="mb-4">
          {{"poles.training.celio.text" | translate}}
        </p>
        <h6 class="text-success">
          {{"poles.training.celio.technologies" | translate}}        </h6>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid py-5">
  <div class="container py-5">
    <div class="row">
      <div class="col-lg-7 pt-lg-5 pb-3">
        <h6 class="text-info font-weight-semi-bold text-uppercase mb-3">
          {{ "references" | translate }}
        </h6>
        <h1 class="mb-4">
          {{ "pole" | translate }} <span class="text-info">SUPPORT </span>
        </h1>
        <div class="row">
          <div
            class="icon-box bg-info text-white rounded-circle d-flex align-items-center justify-content-center"
            style="width: 60px; height: 60px"
          >
            <i class="fa fa-2x fa-user"></i>
          </div>
          <div class="col">
            <h2 class="text-info mb-2" data-toggle="counter-up">CLIENT</h2>
            <h6 class="font-weight-semi-bold mb-sm-4">TRACE ONE</h6>
          </div>
          <div
            class="icon-box bg-info text-white rounded-circle d-flex align-items-center justify-content-center"
            style="width: 60px; height: 60px"
          >
            <i class="fa fa-2x fa-chart-pie"></i>
          </div>
          <div class="col">
            <h2 class="text-info mb-2" data-toggle="counter-up">
              {{ "poles.data.veolia.sector" | translate }}
            </h2>
            <h6 class="font-weight-semi-bold mb-sm-4">{{"poles.support.trace_one.sector" | translate}}</h6>
          </div>
        </div>
        <h2 class="text-info">{{"poles.support.trace_one.description" | translate}}</h2>
        <p class="mb-4">
          {{"poles.support.trace_one.text" | translate}}
        </p>
        <h6 class="text-info">{{"poles.support.trace_one.technologies" | translate}}</h6>
      </div>
      <div class="col-lg-5" style="min-height: 400px">
        <div class="position-relative h-100 rounded overflow-hidden">
          <img
            class="position-absolute w-100 h-100"
            src="https://images.pexels.com/photos/5467600/pexels-photo-5467600.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            style="object-fit: cover"
          />
        </div>
      </div>
    </div>
  </div>
</div>
