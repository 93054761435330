<!-- Start Page Title Area -->
<div class="page-title-area item-bg4">
  <div class="container">
    <div class="page-title-content">
      <h2>{{"about_page" |  translate}}</h2>
    </div>
  </div>

  <div class="lines">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </div>
</div>
<!-- End Page Title Area -->

<!-- Start About Area -->
<!-- <section class="about-area ptb-110">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="about-content mt-n5">
          <h2 class="text-center">We Provide Data Driven Solutions</h2>
          <p>
            CloudiaSys déploie une gamme d’offres d’expertises pensées pour
            répondre aux enjeux majeurs des entreprises et accompagner leurs
            projets de transformation IT.
          </p>
          <p>
            Nos offres allient efficience et simplicité pour atteindre les
            objectifs de compétitivité et de croissance des petites et grandes
            entreprises.
          </p>
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="about-image">
          <img
            src="assets/img/about/1.jpg"
            alt="image"
            style="box-shadow: 0 0 1em grey; border-radius: 4px"
          />
        </div>
      </div>
    </div>

    <div class="about-inner-area">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="about-text">
            <h3>Notre vision</h3>
            <p>
              Vous accompagner dans l’analyse et la valorisation de vos données
              afin de vous aider lors de votre prise de décision.
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="about-text">
            <h3>Notre mission</h3>
            <p>
              Vous aider à gagner en performance et en rentabilité à travers
              l’analyse et la valorisation de la Data.
            </p>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3"
        >
          <div class="about-text">
            <h3>Qui sommes-nous?</h3>
            <p>
              Une équipe composée de spécialistes passionnés par le Cloud,
              Database et Data visualisation.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="shape-img1"><img src="assets/img/shape/1.png" alt="image" /></div>
  <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image" /></div>
  <div class="shape-img3"><img src="assets/img/shape/3.png" alt="image" /></div>
  <div class="shape-img4"><img src="assets/img/shape/4.svg" alt="image" /></div>
  <div class="shape-img5"><img src="assets/img/shape/5.svg" alt="image" /></div>
  <div class="shape-img6"><img src="assets/img/shape/6.png" alt="image" /></div>
  <div class="dot-shape1">
    <img src="assets/img/shape/dot1.png" alt="image" />
  </div>
  <div class="dot-shape2">
    <img src="assets/img/shape/dot3.png" alt="image" />
  </div>
</section> -->
<!-- End About Area -->

<!-- Start About Area -->
<section class="do_section layout_padding ptb-110">
  <div class="container">
    <div class="heading_container">
      <h2>{{"aboutSection.whoWeAre" |  translate}}</h2>
      <p class="font-size-22">
        {{"aboutSection.introduction" |  translate}}
      </p>
    </div>
    <div class="do_container">
      <div class="box arrow_bg">
        <div class="img-box bg-warning">
          <i class="fa fa-3x fa-database text-white"></i>
        </div>
        <div class="detail-box">
          <h6>{{ 'messions.DATA' | translate }}</h6>
        </div>
      </div>
      <div class="box arrow_bg">
        <div class="img-box bg-primary">
          <i class="fa fa-3x fa-gears text-white"></i>
        </div>
        <div class="detail-box">
          <h6>{{ 'messions.DEVOPS' | translate }}</h6>
        </div>
      </div>
      <div class="box arrow_bg">
        <div class="img-box text-dark">
          <i class="fa fa-3x fa-lock text-white"></i>
        </div>
        <div class="detail-box">
          <h6>{{ 'messions.SECURITY' | translate }}</h6>
        </div>
      </div>
      <div class="box arrow_bg">
        <div class="img-box bg-success">
          <i class="fa fa-3x fa-graduation-cap text-white"></i>
        </div>
        <div class="detail-box">
          <h6>{{ 'messions.TRAINING' | translate }}</h6>
        </div>
      </div>
      <div class="box arrow_bg">
        <div class="img-box bg-info">
          <i class="fa fa-3x fa-headphones text-white"></i>
        </div>
        <div class="detail-box">
          <h6>{{ 'messions.SUPPORT' | translate }}</h6>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="container-fluid py-5">
  <div class="container py-5">
    <div class="row">
      <div class="col-lg-5 mb-5 mb-lg-0" style="min-height: 500px">
        <div class="position-relative h-100">
          <img class="position-absolute w-100 h-100 border-raduis-75"
            src="https://images.pexels.com/photos/3183172/pexels-photo-3183172.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            style="object-fit: cover" />
        </div>
      </div>
      <div class="col-lg-7">
        <div class="about-content position-relative mb-4">
          <h1 class="text-center">
            {{"aboutSection.cloudiaSysToday" |  translate}}
          </h1>
        </div>
        <p class="font-size-22">
          {{"aboutSection.recognition" |  translate}}
        </p>
      <!--   <div #KeyNumbers class="row pt-3 mx-0">
          <div class="col-3 px-0">
            <div class="bg-success min-height-150 text-center p-2">
              <h1 class="text-white" id="Employes">0</h1>
              <h6 class="text-uppercase text-white">
                {{"ambitions_section.employees" |  translate}}<span class="d-block"></span>
              </h6>
            </div>
          </div>
          <div class="col-3 px-0">
            <div class="bg-primary min-height-150 text-center p-2">
              <h1 class="text-white" id="Experts">0</h1>
              <h6 class="text-uppercase text-white">
                IT Experts<span class="d-block"></span>
              </h6>
            </div>
          </div>
          <div class="col-3 px-0">
            <div class="bg-secondary min-height-150 text-center p-2">
              <h1 class="text-white" id="Turnover">0</h1>
              <h6 class="text-uppercase text-white">
                {{"ambitions_section.revenue" |  translate}}<span class="d-block"></span>
              </h6>
            </div>
          </div>
          <div class="col-3 px-0">
            <div class="bg-warning min-height-150 text-center p-2">
              <h1 class="text-white" id="Partners">0</h1>
              <h6 class="text-uppercase text-white">
                {{"partenaires" |  translate}}<span class="d-block"></span>
              </h6>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>
<div class="container-fluid bg-image" style="margin: 90px 0">
  <div class="container">
    <div class="row">
      <div class="col-lg-7 my-5 pt-5 pb-lg-">
        <div class="section-title about-content position-relative mb-4">
          <h1 class="d-inline-block position-relative text-secondary text-uppercase pb-2">
            {{"aboutSection.whyUs.title" |  translate}}
          </h1>
        </div>
        <p class="mb-4 pb-2 font-size-20">
          {{"aboutSection.whyUs.content" |  translate}}
        </p>
        <div class="d-flex mb-3">
          <div class="btn-icon bg-primary mr-4">
            <i class="fa fa-2x fa-graduation-cap text-white"></i>
          </div>
          <div class="mt-n1">
            <h4> {{"aboutSection.whyUs.expertise.title" |  translate}}</h4>
            <p class="font-size-20">
              {{"aboutSection.whyUs.expertise.content" |  translate}}
            </p>
          </div>
        </div>
        <div class="d-flex mb-3">
          <div class="btn-icon bg-secondary mr-4">
            <i class="fa fa-2x fa-lightbulb text-white"></i>
          </div>
          <div class="mt-n1">
            <h4>{{"aboutSection.whyUs.customSolutions.title" |  translate}}</h4>
            <p class="font-size-20">
              {{"aboutSection.whyUs.customSolutions.content" |  translate}}
            </p>
          </div>
        </div>
        <div class="d-flex">
          <div class="btn-icon bg-warning mr-4">
            <i class="fa fa-2x fa-phone text-white"></i>
          </div>
          <div class="mt-n1">
            <h4>{{"aboutSection.whyUs.endToEndSupport.title" |  translate}}</h4>
            <p class="m-0 font-size-20">
              {{"aboutSection.whyUs.endToEndSupport.content" |  translate}}
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-5 mb-5 mb-lg-0" style="min-height: 500px">
        <div class="position-relative ">
          <img class="position-absolute w-100 border-raduis-75" style="height: 450px;margin-top: 100px;"
            src="/assets/images/whyus.png"
           />
        </div>
      </div>
    </div>
  </div>
</div>

<!-- End About Area -->
<!-- Start Team Area -->
<!-- <section class="team-area ptb-110">
    <div class="container">
        <div class="section-title">
            <h2>L'équipe</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/1.jpg" alt="team">

                        <div class="social">
                            <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                        </div>
                    </div>

                    <div class="content">
                        <h3>Ahmed LAHMAR</h3>
                        <span>IT Consultant</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/2.jpg" alt="team">

                        <div class="social">
                            <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                        </div>
                    </div>

                    <div class="content">
                        <h3>Karim BELLIR</h3>
                        <span>IT consultant</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/3.jpg" alt="team">

                        <div class="social">
                            <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                        </div>
                    </div>

                    <div class="content">
                        <h3>Zine-Eddine ATIK</h3>
                        <span>IT Consultant</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Team Area -->